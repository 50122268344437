<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>{{ $t('operationFinished') }}</h2>
      </div>
      <div class="one-column first">
        {{ $t('operation_note_1') }}
      </div>
      <div class="one-column last">
        {{ $t('operation_note_2') }}
      </div>
      <div class="two-column-grid">
        <div>{{ $t('city') }}, {{ $t('date') }}</div>
        <div>{{ project.customer.city }}, {{ $date() }}</div>

        <div>{{ $t('signatureOperationCustomer') }}</div>
        <div v-if="project.enpalReport.signatureOperationCustomer">
          <img
            v-if="project.enpalReport.signatureOperationCustomer"
            :src="project.enpalReport.signatureOperationCustomer"
            :alt="$t('signatureOperationCustomer')"
          />
        </div>
        <div v-else>
          <img
            v-if="project.enpalReport.uploadedSignatureOperationCustomer"
            :src="project.enpalReport.uploadedSignatureOperationCustomer.path"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
      </div>

      <div class="one-column last">
        {{ $t('operation_note_3') }}
      </div>
      <div class="two-column-grid">
        <div>{{ $t('signatureOperationTechnician') }}</div>
        <div v-if="project.enpalReport.signatureOperationTechnician">
          <img
            v-if="project.enpalReport.signatureOperationTechnician"
            :src="project.enpalReport.signatureOperationTechnician"
            :alt="$t('signatureOperationTechnician')"
          />
        </div>
        <div v-else>
          <img
            v-if="project.enpalReport.uploadedSignatureOperationTechnician"
            :src="project.enpalReport.uploadedSignatureOperationTechnician.path"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
};
</script>
