<template>
  <AttachmentsPage
    :pageNumber="pageNumber"
    :attachments="project.enpalReport.damageAttachments"
    fieldName="existingDamageDocumentation"
    fieldDescription="annotations"
    :maxImages="3"
  >
    <template v-slot:content>
      <div class="content-box">
        <div class="content-box-header">
          <h2>{{ $t('installationTeamDocumentation') }}</h2>
        </div>
        <div class="content-box-header">
          <h3>{{ $t('technicalSystemData') }}</h3>
        </div>
        <div class="two-column-grid">
          <div>{{ $t('systemType') }}</div>
          <div>{{ $t(project.enpalReport.solarSystemType) }}</div>
          <div>{{ $t('solarPanelCount') }}</div>
          <div>{{ project.solarPlant.solarPanelCount }}</div>
          <div>{{ $t('composite.storagePanelManufacturer_storagePanelType') }}</div>
          <div>
            {{ project.solarPlant.solarPanel.manufacturer }} -
            {{ project.solarPlant.solarPanel.type }}
          </div>
          <div>{{ $t('solarPlantNominalPower') }}</div>
          <div>{{ $n(project.solarPlant.solarPanel.powerKwp, 'decimal') }}</div>
        </div>
      </div>
    </template>
  </AttachmentsPage>
</template>

<script>
import AttachmentsPage from '@/views/Measurement/AttachmentsPage';

export default {
  components: {
    AttachmentsPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {},
};
</script>
