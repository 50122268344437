<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('systemPowerOn_1') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('systemPowerOn_2') }}</div>
        <div>
          {{ project.enpalReport.systemPowerOn_2 }}
        </div>

        <div>{{ $t('systemPowerOnAttachment_1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.systemPowerOnAttachment_1"
            :src="project.enpalReport.systemPowerOnAttachment_1.path"
          />
        </div>
        <div>{{ $t('systemPowerOnAttachment_2') }}</div>
        <div>
          <img
            v-if="project.enpalReport.systemPowerOnAttachment_2"
            :src="project.enpalReport.systemPowerOnAttachment_2.path"
          />
        </div>
        <div>{{ $t('systemPowerOnAttachment_3') }}</div>
        <div>
          <img
            v-if="project.enpalReport.systemPowerOnAttachment_3"
            :src="project.enpalReport.systemPowerOnAttachment_3.path"
          />
        </div>
        <div>{{ $t('systemPowerOn_3') }}</div>
        <div>
          {{ project.enpalReport.systemPowerOn_3 }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
