<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSP />
      </slot>
    </template>

    <PrintProjectHeaderSSP :project="project">
      <template v-slot:header>
        <slot>
          {{ $t('acceptanceAndOperationReport') }}
        </slot>
      </template>
    </PrintProjectHeaderSSP>

    <div class="content-box">
      <div class="content-box-header">
        <h2>{{ $t('acceptanceFaultFree') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('approvalReportState') }}</div>
        <div>{{ $t('faultFree') }}</div>
        <!-- <div>{{ $t('customerInstructionHappened') }}</div>
        <div>{{ $t(project.enpalReport.customerInstructionHappenedState) }}</div> -->
      </div>
      <div class="one-column">
        {{ $t('acceptance_note_1') }}
      </div>
      <div class="one-column last">
        {{ $t('acceptance_note_2') }}
      </div>
      <div class="two-column-grid">
        <div>{{ $t('city') }}, {{ $t('date') }}</div>
        <div>{{ project.customer.city }}, {{ $date() }}</div>
        <div>{{ $t('signatureApprovalCustomer') }}</div>
        <div v-if="project.enpalReport.signatureApprovalCustomer">
          <img
            v-if="project.enpalReport.signatureApprovalCustomer"
            :src="project.enpalReport.signatureApprovalCustomer"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
        <div v-else>
          <img
            v-if="project.enpalReport.uploadedSignatureApprovalCustomer"
            :src="project.enpalReport.uploadedSignatureApprovalCustomer.path"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
        <div>{{ $t('signatureApprovalTechnician') }}</div>
        <div v-if="project.enpalReport.signatureApprovalTechnician">
          <img
            v-if="project.enpalReport.signatureApprovalTechnician"
            :src="project.enpalReport.signatureApprovalTechnician"
            :alt="$t('signatureApprovalTechnician')"
          />
        </div>
        <div v-else>
          <img
            v-if="project.enpalReport.uploadedSignatureApprovalTechnician"
            :src="project.enpalReport.uploadedSignatureApprovalTechnician.path"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSP from '@/components/Print/PrintHeaderSSP';
import PrintProjectHeaderSSP from '@/components/Print/PrintProjectHeaderSSP';

export default {
  components: {
    PrintPage,
    PrintHeaderSSP,
    PrintProjectHeaderSSP,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
};
</script>
