<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>{{ $t('electricMeterChange') }} - Ein- u. Ausbaumeldung (Smart Meter)</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('electricMeterChange_1') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_1 }}
        </div>
        <div>{{ $t('electricMeterChange_2') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_2 }}
        </div>
        <div>{{ $t('electricMeterChange_3') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_3 }}
        </div>
        <div>{{ $t('electricMeterChange4State') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterChange4State) }}
        </div>
        <div>{{ $t('electricMeterChange_5') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_5 }}
        </div>
        <div>{{ $t('electricMeterChange_6') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_6 }}
        </div>
        <div>{{ $t('electricMeterChange7State') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterChange7State) }}
        </div>
      </div>

      <div class="one-column">
        {{ $t('electricMeterChange_8') }}
      </div>

      <div class="two-column-grid">
        <div>{{ $t('electricMeterChange_9') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_9 }}
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('electricMeterChange') }} - Alter Zähler (Ausbau) - 1 Zähler, 1 Tarif</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('electricMeterChangeAttachment_1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.electricMeterChangeAttachment_1"
            :src="project.enpalReport.electricMeterChangeAttachment_1.path"
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('electricMeterChange_10') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_10 }}
        </div>
      </div>

      <div class="one-column">
        {{ $t('electricMeterChange_11') }}
      </div>

      <div class="two-column-grid" v-for="i in range(1, 2)" :key="i">
        <div>{{ $t('electricMeterChangeAttachments_2') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.electricMeterChangeAttachments_2[i - 1]"
            :src="project.enpalReport.electricMeterChangeAttachments_2[i - 1].path"
            alt=""
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
