<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-outer-box">
      <div class="heading">
        <h2>{{ $t('installationTeamDocumentation') }}</h2>
      </div>
      <div class="content-inner-box">
        <div class="heading">
          <h3>{{ $t('technicalSystemData') }}</h3>
        </div>
        <div class="two-column-grid">
          <div>{{ $t('systemType') }}</div>
          <div>{{ $t(project.enpalReport.solarSystemType) }}</div>
          <div>{{ $t('solarPanelCount') }}</div>
          <div>{{ project.solarPlant.solarPanelCount }}</div>
          <div>{{ $t('composite.storagePanelManufacturer_storagePanelType') }}</div>
          <div>
            {{ project.solarPlant.solarPanel.manufacturer }} -
            {{ project.solarPlant.solarPanel.type }}
          </div>
          <div>{{ $t('solarPlantNominalPower') }}</div>
          <div>{{ $n(project.solarPlant.solarPanel.powerKwp, 'decimal') }}</div>
        </div>
      </div>

      <div class="content-inner-box">
        <div class="heading">
          <h3>{{ $t('existingDamageDocumentation') }}</h3>
        </div>
        <div class="two-column-grid">
          <div>{{ $t('annotations') }}</div>
          <div>{{ project.enpalReport.existingDamageNotes }}</div>
        </div>
        <div class="two-column-grid" v-for="i in range(1, 3)" :key="i">
          <div>{{ $t('image') }} {{ i }} {{ $t('existingDamage') }}</div>
          <div>
            <img
              v-if="project.enpalReport.damageAttachments[i - 1]"
              :src="project.enpalReport.damageAttachments[i - 1].thumbnail"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
