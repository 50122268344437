<template>
  <div v-if="project && pages" class="document">
    <AcceptanceFaultFree :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <OperationFinished :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ExistingDamagePrintPage :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <JobSafetyPrintPage :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <BrickAndSubstructurePrintPage1
      v-if="isPitchedRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <BrickAndSubstructurePrintPage2
      v-if="isPitchedRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <BrickAndSubstructurePrintPage3
      v-if="isPitchedRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <BallastAndSubstructurePrintPage1
      v-if="isFlatRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <BallastAndSubstructurePrintPage2
      v-if="isFlatRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <SolarPanelsPrintPage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <SolarPanelsPrintPagePitchedRoof
      v-if="isPitchedRoof"
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <SolarPanelsPrintPage2 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <CableManagementPrintPage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <CableManagementPrintPage2 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage2 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <InverterPrintPage3 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <PotentialPrintPage :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <HakPrintPage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage2 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage3 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ElectricMeterPrintPage4 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <WallboxPrintpage1 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <WallboxPrintpage2 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <MonitoringAndConfigurationPrintPage1
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <MonitoringAndConfigurationPrintPage2
      :pageCount="pages"
      :pageNumber="currentPage()"
      :project="project"
    />
    <MeasurementPrintPage :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <DocumentsPrintPage :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <!-- <ReportPrintPage4 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage4A :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage5 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage6 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage7 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage8 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage9 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage10 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage11 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage12 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage13 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage14 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage15 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage16 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage17 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage18 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage19 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage20 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage21 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage22 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage23 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage24 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage25 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage26 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage27 :pageCount="pages" :pageNumber="currentPage()" :project="project" />
    <ReportPrintPage28 :pageCount="pages" :pageNumber="currentPage()" :project="project" /> -->
  </div>
  <div v-else>{{ $t('no_data_found') }}</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ExtendedProject } from '@/helpers/projects';
import AcceptanceFaultFree from '@/views/Report/AcceptanceFaultFree';
import OperationFinished from '@/views/Report/OperationFinished';
import ExistingDamagePrintPage from '@/views/Report/ExistingDamagePrintPage';
import BrickAndSubstructurePrintPage1 from '@/views/Report/BrickAndSubstructurePrintPage1';
import BrickAndSubstructurePrintPage2 from '@/views/Report/BrickAndSubstructurePrintPage2';
import BrickAndSubstructurePrintPage3 from '@/views/Report/BrickAndSubstructurePrintPage3';
import JobSafetyPrintPage from '@/views/Report/JobSafetyPrintPage';
import BallastAndSubstructurePrintPage1 from '@/views/Report/BallastAndSubstructurePrintPage1';
import BallastAndSubstructurePrintPage2 from '@/views/Report/BallastAndSubstructurePrintPage2';
import SolarPanelsPrintPage1 from '@/views/Report/SolarPanelsPrintPage1';
import SolarPanelsPrintPagePitchedRoof from '@/views/Report/SolarPanelsPrintPagePitchedRoof';
import SolarPanelsPrintPage2 from '@/views/Report/SolarPanelsPrintPage2';
import CableManagementPrintPage1 from '@/views/Report/CableManagementPrintPage1';
import CableManagementPrintPage2 from '@/views/Report/CableManagementPrintPage2';
import InverterPrintPage1 from '@/views/Report/InverterPrintPage1';
import InverterPrintPage2 from '@/views/Report/InverterPrintPage2';
import InverterPrintPage3 from '@/views/Report/InverterPrintPage3';
import PotentialPrintPage from '@/views/Report/PotentialPrintPage';
import ElectricMeterPrintPage1 from '@/views/Report/ElectricMeterPrintPage1';
import ElectricMeterPrintPage2 from '@/views/Report/ElectricMeterPrintPage2';
import ElectricMeterPrintPage4 from '@/views/Report/ElectricMeterPrintPage4';
import ElectricMeterPrintPage3 from '@/views/Report/ElectricMeterPrintPage3';
import MonitoringAndConfigurationPrintPage1 from '@/views/Report/MonitoringAndConfigurationPrintPage1';
import MonitoringAndConfigurationPrintPage2 from '@/views/Report/MonitoringAndConfigurationPrintPage2';
import MeasurementPrintPage from '@/views/Report/MeasurementPrintPage';
import HakPrintPage1 from '@/views/Report/HakPrintPage1';
import WallboxPrintpage1 from '@/views/Report/WallboxPrintpage1';
import WallboxPrintpage2 from '@/views/Report/WallboxPrintpage2';
import DocumentsPrintPage from '@/views/Report/DocumentsPrintPage';
import ReportPrintPage4 from '@/views/Report/ReportPrintPage4';
import ReportPrintPage4A from '@/views/Report/ReportPrintPage4A';
import ReportPrintPage5 from '@/views/Report/ReportPrintPage5';
import ReportPrintPage6 from '@/views/Report/ReportPrintPage6';
import ReportPrintPage7 from '@/views/Report/ReportPrintPage7';
import ReportPrintPage8 from '@/views/Report/ReportPrintPage8';
import ReportPrintPage9 from '@/views/Report/ReportPrintPage9';
import ReportPrintPage10 from '@/views/Report/ReportPrintPage10';
import ReportPrintPage11 from '@/views/Report/ReportPrintPage11';
import ReportPrintPage12 from '@/views/Report/ReportPrintPage12';
import ReportPrintPage13 from '@/views/Report/ReportPrintPage13';
import ReportPrintPage14 from '@/views/Report/ReportPrintPage14';
import ReportPrintPage15 from '@/views/Report/ReportPrintPage15';
import ReportPrintPage16 from '@/views/Report/ReportPrintPage16';
import ReportPrintPage17 from '@/views/Report/ReportPrintPage17';
import ReportPrintPage18 from '@/views/Report/ReportPrintPage18';
import ReportPrintPage19 from '@/views/Report/ReportPrintPage19';
import ReportPrintPage20 from '@/views/Report/ReportPrintPage20';
import ReportPrintPage21 from '@/views/Report/ReportPrintPage21';
import ReportPrintPage22 from '@/views/Report/ReportPrintPage22';
import ReportPrintPage23 from '@/views/Report/ReportPrintPage23';
import ReportPrintPage24 from '@/views/Report/ReportPrintPage24';
import ReportPrintPage25 from '@/views/Report/ReportPrintPage25';
import ReportPrintPage26 from '@/views/Report/ReportPrintPage26';
import ReportPrintPage27 from '@/views/Report/ReportPrintPage27';
import ReportPrintPage28 from '@/views/Report/ReportPrintPage28';
import WallboxPrintpage1Vue from './WallboxPrintpage1.vue';

export default {
  components: {
    AcceptanceFaultFree,
    OperationFinished,
    ExistingDamagePrintPage,
    JobSafetyPrintPage,
    BrickAndSubstructurePrintPage1,
    BrickAndSubstructurePrintPage2,
    BrickAndSubstructurePrintPage3,
    BallastAndSubstructurePrintPage1,
    BallastAndSubstructurePrintPage2,
    SolarPanelsPrintPage1,
    SolarPanelsPrintPagePitchedRoof,
    SolarPanelsPrintPage2,
    CableManagementPrintPage1,
    CableManagementPrintPage2,
    InverterPrintPage1,
    InverterPrintPage2,
    InverterPrintPage3,
    PotentialPrintPage,
    ElectricMeterPrintPage1,
    ElectricMeterPrintPage2,
    ElectricMeterPrintPage3,
    ElectricMeterPrintPage4,
    MonitoringAndConfigurationPrintPage1,
    MonitoringAndConfigurationPrintPage2,
    MeasurementPrintPage,
    HakPrintPage1,
    WallboxPrintpage1,
    WallboxPrintpage2,
    DocumentsPrintPage,
    // ReportPrintPage4,
    // ReportPrintPage4A,
    // ReportPrintPage5,
    // ReportPrintPage6,
    // ReportPrintPage7,
    // ReportPrintPage8,
    // ReportPrintPage9,
    // ReportPrintPage10,
    // ReportPrintPage11,
    // ReportPrintPage12,
    // ReportPrintPage13,
    // ReportPrintPage14,
    // ReportPrintPage15,
    // ReportPrintPage16,
    // ReportPrintPage17,
    // ReportPrintPage18,
    // ReportPrintPage19,
    // ReportPrintPage20,
    // ReportPrintPage21,
    // ReportPrintPage22,
    // ReportPrintPage23,
    // ReportPrintPage24,
    // ReportPrintPage25,
    // ReportPrintPage26,
    // ReportPrintPage27,
    // ReportPrintPage28,
  },
  data() {
    return {
      // pages: 28,
    };
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getProjectByNumber']),
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
    pages() {
      // console.log(this.project.enpalReport.damageAttachments.length);
      // if (this.project.enpalReport.damageAttachments.length > 7) {
      //   return 28;
      // }
      // if (this.project.enpalReport.damageAttachments.length > 2) {
      //   return 27;
      // }
      return 28;
    },
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber']),
    currentPage() {
      this.pageCounter += 1;
      return this.pageCounter;
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
  },
  created() {
    this.pageCounter = 0; // create non reactive data property
  },
};
</script>
<style src="../Measurement/MeasurementPrintPage.scss" lang="scss" />
