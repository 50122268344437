<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - X. {{ $t('storage') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('storageInstalledState') }}</div>
        <div>
          {{ $t(project.enpalReport.storageInstalledState) }}
        </div>
        <div>{{ $t('storageNotes') }}</div>
        <div>
          {{ project.enpalReport.storageNotes }}
        </div>
        <div>{{ $t('storageAState') }}</div>
        <div>
          {{ $t(project.enpalReport.storageAState) }}
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('storageAttachment_B1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachment_B1"
            :src="project.enpalReport.storageAttachment_B1.path"
          />
        </div>
        <div>{{ $t('storageAttachment_B2') }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachment_B2"
            :src="project.enpalReport.storageAttachment_B2.path"
          />
        </div>
        <div>{{ $t('storageAttachment_C1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachment_C1"
            :src="project.enpalReport.storageAttachment_C1.path"
          />
        </div>
        <div>{{ $t('storageAttachment_C2') }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachment_C2"
            :src="project.enpalReport.storageAttachment_C2.path"
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
