<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>2. {{ $t('brickAndUk') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('annotations') }}</div>
        <div>
          {{ project.enpalReport.brickAndUkNotes }}
        </div>
        <div>{{ $t('brickAndUkInstallationType') }}</div>
        <div>
          {{ project.enpalReport.brickAndUkInstallationType }}
        </div>
        <div>{{ $t('brickAndUkAttachment_X') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_X"
            :src="project.enpalReport.brickAndUkAttachment_X.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkAttachment_A') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_A"
            :src="project.enpalReport.brickAndUkAttachment_A.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkA2State') }}</div>
        <div>
          {{ $t(project.enpalReport.brickAndUkA2State) }}
        </div>
        <div>{{ $t('brickAndUkBState') }}</div>
        <div>
          {{ $t(project.enpalReport.brickAndUkBState) }}
        </div>
        <div>{{ $t('brickAndUkAttachment_C1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_C1"
            :src="project.enpalReport.brickAndUkAttachment_C1.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkAttachment_C1_2') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_C1_2"
            :src="project.enpalReport.brickAndUkAttachment_C1_2.path"
            alt=""
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
