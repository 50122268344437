<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <slot name="content"></slot>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t(fieldName) }}</h3>
      </div>
      <div class="two-column-grid" v-for="i in range(1, maxImages)" :key="i">
        <div>{{ $t('image') }} {{ i }} {{ $t(fieldDescription) }}</div>
        <div>
          <img v-if="attachments[i - 1]" :src="thumbnail(attachments[i - 1])" />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import { range } from '@/helpers/math.js';

export default {
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
  },
  props: {
    attachments: null,
    maxImages: { type: Number, default: 4 },
    fieldName: { type: String, default: '' },
    fieldDescription: { type: String, default: '' },
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {},
  methods: {
    /**
     * some paths come without scheme, prefix with https://
     */
    sanitizePath(path) {
      if (path.indexOf('https://') != 0) {
        return 'https://' + path;
      }
      return path;
    },

    /**
     * return the thumbnail if available
     */
    thumbnail(attachment) {
      if (attachment.thumbnail) {
        return this.sanitizePath(attachment.thumbnail);
      }
      return this.sanitizePath(attachment.path);
    },
    range,
  },
};
</script>
