<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 4. {{ $t('cableManagement') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('cableManagementNotes') }}</div>
        <div>
          {{ project.enpalReport.cableManagementNotes }}
        </div>
        <div>{{ $t('cableManagement_A') }}</div>
        <div>
          {{ project.enpalReport.cableManagement_A }}
        </div>
        <div>{{ $t('cableManagementBState') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementBState) }}
        </div>
        <div>{{ $t('cableManagementC1State') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementC1State) }}
        </div>
        <div>{{ $t('cableManagementC2State') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementC2State) }}
        </div>
        <div>{{ $t('cableManagementC3State') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementC3State) }}
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('cableManagementAttachment_D') }}</div>
        <div>
          <img
            v-if="project.enpalReport.cableManagementAttachment_D"
            :src="project.enpalReport.cableManagementAttachment_D.path"
            alt=""
          />
        </div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('cableManagementAttachment_X') }}</div>
        <div>
          <img
            v-if="project.enpalReport.cableManagementAttachment_X"
            :src="project.enpalReport.cableManagementAttachment_X.path"
            alt=""
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('cableManagementYState') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementYState) }}
        </div>
        <div>{{ $t('cableManagementEState') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementEState) }}
        </div>
        <div>{{ $t('cableManagementFState') }}</div>
        <div>
          {{ $t(project.enpalReport.cableManagementFState) }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
