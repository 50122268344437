<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 5. {{ $t('inverter') }}</h3>
      </div>
      <div class="two-column-grid" v-for="i in range(4, 4)" :key="i">
        <div>F{{ i }}) {{ $t('inverterAttachmentsAdditional_F') }} {{ $t('image') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachmentsAdditional_F[i - 1]"
            :src="project.enpalReport.inverterAttachmentsAdditional_F[i - 1].path"
            alt=""
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterF5State') }}</div>
        <div>
          {{ $t(project.enpalReport.inverterF5State) }}
        </div>
        <div>{{ $t('inverterGState') }}</div>
        <div>
          {{ $t(project.enpalReport.inverterGState) }}
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterAttachment_G2') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_G2"
            :src="project.enpalReport.inverterAttachment_G2.path"
          />
        </div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('inverterModuleCount') }}</div>
        <div>
          {{ project.solarPlant.solarPanelCount }}
        </div>
      </div>

      <div class="one-column">
        Wirkleistungs-Screenshot:
        <ul>
          <li>Steuermodus: Auf "Leistungsbeschränkter Netzanschluss (kW)" eingestellt</li>
          <li>Closed-Loop-Steuergerät: Auf "Wechselrichter" eingestellt</li>
          <li>PV-Anlagenkapazität: Auf "Gesamtleistung" eingestellt</li>
          <li>
            Netzeinspeisungsstrom maximal: Anlagenspezifisch eingestellt (siehe Angefügte Übersicht)
          </li>
        </ul>
        {{ project.solarPlant.solarPanelCount }} Module:
        {{ $n(project.solarPlant.solarPlantOverallPower, 'decimal_triple') }} kWp
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterAttachment_H') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_H"
            :src="project.enpalReport.inverterAttachment_H.path"
          />
        </div>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('inverterIState') }}</div>
        <div>
          {{ $t(project.enpalReport.inverterIState) }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
