<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 3. {{ $t('solarPanels') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('annotations') }}</div>
        <div>
          {{ project.enpalReport.solarPanelNotes }}
        </div>

        <div>{{ $t('solarPanelAttachment_A') }}</div>
        <div>
          <img
            v-if="project.enpalReport.solarPanelAttachment_A"
            :src="project.enpalReport.solarPanelAttachment_A.path"
            alt=""
          />
        </div>

        <div>{{ $t('solarPanelAttachment_B1') }}</div>
        <div>
          <img
            v-if="project.enpalReport.solarPanelAttachment_B1"
            :src="project.enpalReport.solarPanelAttachment_B1.path"
            alt=""
          />
        </div>

        <div>{{ $t('solarPanelB2State') }}</div>
        <div>
          {{ $t(project.enpalReport.solarPanelB2State) }}
        </div>
        <div>{{ $t('solarPanelB3State') }}</div>
        <div>
          {{ $t(project.enpalReport.solarPanelB3State) }}
        </div>
        <div>{{ $t('solarPanelB4State') }}</div>
        <div>
          {{ $t(project.enpalReport.solarPanelB4State) }}
        </div>
        <div>{{ $t('solarPanelB5State') }}</div>
        <div>
          {{ $t(project.enpalReport.solarPanelB5State) }}
        </div>
      </div>

      <div class="two-column-grid" v-for="i in range(1, 2)" :key="i">
        <div>{{ $t('solarPanelAttachments_C') }} {{ $t('image') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.solarPanelAttachments_C[i - 1]"
            :src="project.enpalReport.solarPanelAttachments_C[i - 1].path"
            alt=""
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
