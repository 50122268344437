<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 2. {{ $t('brickAndUk') }}</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('brickAndUkAttachment_C1_3') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_C1_3"
            :src="project.enpalReport.brickAndUkAttachment_C1_3.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkDState') }}</div>
        <div>
          {{ $t(project.enpalReport.brickAndUkDState) }}
        </div>
        <div>{{ $t('brickAndUkAttachment_E') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_E"
            :src="project.enpalReport.brickAndUkAttachment_E.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkFState') }}</div>
        <div>
          {{ $t(project.enpalReport.brickAndUkFState) }}
        </div>
        <div>{{ $t('brickAndUkAttachment_G') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_G"
            :src="project.enpalReport.brickAndUkAttachment_G.path"
            alt=""
          />
        </div>
        <div>{{ $t('brickAndUkAttachment_H') }}</div>
        <div>
          <img
            v-if="project.enpalReport.brickAndUkAttachment_H"
            :src="project.enpalReport.brickAndUkAttachment_H.path"
            alt=""
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
