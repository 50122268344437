<template>
  <PrintPage :pageNumber="pageNumber">
    <template v-slot:header>
      <slot>
        <PrintHeaderSSPSlim />
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h2>4. {{ $t('cableManagement') }}</h2>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('cableManagementC2State') }}</div>
        <div>{{ $t(project.enpalReport.cableManagementC2State) }}</div>
      </div>
    </div>

    <AttachmentsPrintGrid
      :attachments="project.cableManagementAttachments"
      fieldName="cableManagementAttachments"
      :maxImages="4"
    />

    <AttachmentsPrintGrid
      :attachments="project.cableAlongFacadeAttachments"
      fieldName="cableAlongFacadeAttachments"
      :maxImages="4"
    />
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';
import PrintHeaderSSPSlim from '@/components/Print/PrintHeaderSSPSlim';
import AttachmentsPrintGrid from '@/components/Print/AttachmentsPrintGrid';
import { range } from '@/helpers/math.js';

export default {
  name: 'MeasurementPrintPage1',
  components: {
    PrintPage,
    PrintHeaderSSPSlim,
    AttachmentsPrintGrid,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: null },
    pageNumber: { type: Number, default: 0 },
  },
  computed: {
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },
  methods: { range },
};
</script>
