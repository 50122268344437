<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - X. {{ $t('storage') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('storageAttachment_D') }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachment_D"
            :src="project.enpalReport.storageAttachment_D.path"
          />
        </div>
      </div>

      <div class="one-column">
        Speicher-Sicherheitsabstände:
        <ul>
          <li>32 cm seitlich & oben</li>
          <li>100 cm vorne</li>
        </ul>
      </div>

      <div class="two-column-grid" v-for="i in range(1, 3)" :key="i">
        <div>D{{ i }}) {{ $t('storageAttachments_D1') }} {{ $t('image') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.storageAttachments_D1[i - 1]"
            :src="project.enpalReport.storageAttachments_D1[i - 1].path"
            alt=""
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('storageEState') }}</div>
        <div>
          {{ $t(project.enpalReport.storageEState) }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
