<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 8. {{ $t('additionalImages') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('additionalImagesNotes') }}</div>
        <div>
          {{ project.enpalReport.additionalImagesNotes }}
        </div>
        <div>{{ $t('additionalImagesEnpalNotes') }}</div>
        <div>
          {{ project.enpalReport.additionalImagesEnpalNotes }}
        </div>
      </div>

      <div class="two-column-grid" v-for="i in range(1, 4)" :key="i">
        <div>{{ $t('additionalImageAttachments') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.additionalImageAttachments[i - 1]"
            :src="project.enpalReport.additionalImageAttachments[i - 1].path"
            alt=""
          />
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
