<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('electricMeterChange') }} - Abschlussinformationen Zählertausch</h3>
      </div>
      <div class="two-column-grid">
        <div>{{ $t('electricMeterChange16State') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterChange16State) }}
        </div>
        <div>{{ $t('electricMeterChangeAttachment_6') }}</div>
        <div>
          <img
            v-if="project.enpalReport.electricMeterChangeAttachment_6"
            :src="project.enpalReport.electricMeterChangeAttachment_6.path"
          />
        </div>
        <div>{{ $t('electricMeterChange_17') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_17 }}
        </div>
        <div>{{ $t('electricMeterChange18State') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterChange18State) }}
        </div>
        <div>{{ $t('electricMeterChange_19') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_19 }}
        </div>
        <div>{{ $t('electricMeterChange20State') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterChange20State) }}
        </div>
        <div>{{ $t('electricMeterChange_21') }}</div>
        <div>
          {{ project.enpalReport.electricMeterChange_21 }}
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('electricMeterChange') }} - {{ $t('signature') }}</h3>
      </div>
      <div class="one-column">
        Elektroinstallationsunternehmen:
      </div>

      <div class="two-column-grid">
        <div>{{ $t('electricMeterChangeCompany') }}</div>
        <div>
          {{ project.electrician.name }},
          {{ project.composite.electricianStreet_electricianStreetNumber }}
          {{ project.composite.electricianZip_electricianCity }}
        </div>
        <div>{{ $t('electricMeterChangeAt') }}</div>
        <div>
          {{ $date(project.enpalReport.electricMeterChangeAt) }}
        </div>
        <div>{{ $t('electricMeterChangeAt') }}</div>
        <div>
          {{ $time(project.enpalReport.electricMeterChangeAt) }}
        </div>

        <div>{{ $t('signatureApprovalCustomer') }}</div>
        <div>
          <img
            v-if="project.electrician.signatureAttachments.length > 0"
            :src="project.electrician.signatureAttachments[0].path"
            :alt="$t('signatureApprovalCustomer')"
          />
        </div>
        <div>{{ $t('electricMeterHasBeenChangedState') }}</div>
        <div>
          {{ $t(project.enpalReport.electricMeterHasBeenChangedState) }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
