<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header last">
        <h2>
          {{ $t('journal') }}: {{ $t('measurements') }}, {{ $t('tidiness') }} {{ $t('and') }}
          {{ $t('systemPowerOn') }}
        </h2>
      </div>
    </div>
    <div class="content-box">
      <div class="content-box-header">
        <h3>1. {{ $t('measurements') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('journalMeasurementsStringCount') }}</div>
        <div>
          {{ project.enpalReport.journalMeasurementsStringCount }}
        </div>
      </div>

      <div class="two-column-grid" v-for="i in range(1, 2)" :key="i">
        <div>{{ $t('journalMeasurementsAttachment_A') }} {{ i }}</div>
        <div>
          <img
            v-if="project.enpalReport.journalMeasurementsAttachment_A[i - 1]"
            :src="project.enpalReport.journalMeasurementsAttachment_A[i - 1].path"
            alt=""
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('journalMeasurementsBState') }}</div>
        <div>
          {{ $t(project.enpalReport.journalMeasurementsBState) }}
        </div>
        <div>{{ $t('journalMeasurements_C1') }}</div>
        <div>
          {{ project.enpalReport.journalMeasurements_C1 }}
        </div>
      </div>

      <div class="one-column">
        {{ $t('journalMeasurements_C2') }}
      </div>

      <div class="two-column-grid">
        <div>{{ $t('journalMeasurementsCState') }}</div>
        <div>
          {{ $t(project.enpalReport.journalMeasurementsCState) }}
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="content-box-header">
        <h3>2. {{ $t('tidiness') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('tidiness_A') }}</div>
        <div>
          {{ project.enpalReport.tidiness_A }}
        </div>
        <div>{{ $t('tidinessBState') }}</div>
        <div>
          {{ $t(project.enpalReport.tidinessBState) }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
