<template>
  <PrintPage :pageNumber="pageNumber" :pageCount="pageCount">
    <template v-slot:header>
      <slot>
        <div class="header-grid"></div>
      </slot>
    </template>

    <div class="content-box">
      <div class="content-box-header">
        <h3>{{ $t('installationTeamDocumentation') }} - 5. {{ $t('inverter') }}</h3>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('annotations') }}</div>
        <div>
          {{ project.enpalReport.inverterNotes }}
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterAttachment_A') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_A"
            :src="project.enpalReport.inverterAttachment_A.path"
          />
        </div>
        <div>{{ $t('inverterAttachment_B') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_B"
            :src="project.enpalReport.inverterAttachment_B.path"
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterBState') }}</div>
        <div>
          {{ $t(project.enpalReport.inverterBState) }}
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterAttachment_C') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_C"
            :src="project.enpalReport.inverterAttachment_C.path"
          />
        </div>
        <div>{{ $t('inverterAttachment_D') }}</div>
        <div>
          <img
            v-if="project.enpalReport.inverterAttachment_D"
            :src="project.enpalReport.inverterAttachment_D.path"
          />
        </div>
      </div>

      <div class="two-column-grid">
        <div>{{ $t('inverterD1State') }}</div>
        <div>
          {{ $t(project.enpalReport.inverterD1State) }}
        </div>
        <div>{{ $t('inverter_D2') }}</div>
        <div>
          {{ project.enpalReport.inverter_D2 }}
        </div>
      </div>
    </div>
  </PrintPage>
</template>

<script>
import PrintPage from '@/components/Print/PrintPage';

export default {
  components: {
    PrintPage,
  },
  props: {
    project: null,
    pageCount: { type: Number, default: 1 },
    pageNumber: { type: Number, default: 0 },
  },
  methods: {
    range(min, max) {
      const array = [];
      let j = 0;
      for (var i = min; i <= max; i++) {
        array[j] = i;
        j++;
      }
      return array;
    },
  },
};
</script>

<style src="./ReportPrintPage.scss" lang="scss" />
